"use client";

import ReactDOM from "react-dom";

interface IPreconnectProps {}
const Preconnect: React.FC<IPreconnectProps> = () => {
	if (process.env.NEXT_PRECONNECT_MEDIA_URL) {
		ReactDOM.preconnect(process.env.NEXT_PRECONNECT_MEDIA_URL, { crossOrigin: "anonymous" });
	}
	if (process.env.NEXT_PRECONNECT_MATOMO_URL) {
		ReactDOM.preconnect(process.env.NEXT_PRECONNECT_MATOMO_URL, { crossOrigin: "anonymous" });
	}

	return null;
};

export default Preconnect;
